export const FULL_NAME =
  /^[A-Za-z ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏõốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựứýỳỵỷỹVv]{6,40}$/;

export const IDENTIFY = /^[0-9]*$/;

export const PHONE_NUMBER = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]{4,20}$/;

export const EMAIL = /^([a-zA-Z0-9_\-\.]+)@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const HTTP_LINK =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

// Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number.
export const PASSWORD_FAIR = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,}$/;
// Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.
export const PASSWORD_GOOD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/;
// Minimum 10 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.
export const PASSWORD_EXCELLENT =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{10,}$$/;

export const MY_CODE_PEN = /https:\/\/codepen.io\/sillverit/;
export const CLOUDINARY_EHPHUONG = /https:\/\/res.cloudinary.com\/ephuong/;
